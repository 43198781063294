<template>
  <div class="special-ico">
    <Header titleurl="special" v-if="fromoa != '1'" />
    <div id="container">
      <div id="content" class="w">
        <div class="checkout-tit">
          <span class="tit-txt">填写并核对订单信息</span>
        </div>
        <div class="checkout-steps">
          <div class="step-tit">
            <h3>收货人信息</h3>
            <div class="extra-r">
              <a
                href="javascript:;"
                class="ftx-05 J_consignee_global"
                @click="onshowaddpop"
                >新增收货地址</a
              >
            </div>
          </div>
          <div class="step-cont">
            <div class="consignee-content">
              <div class="consignee-scrollbar">
                <div class="ui-scrollbar-main">
                  <div class="consignee-scroll">
                    <div class="consignee-cont">
                      <ul>
                        <li
                          v-for="(item, index) in shippingaddresslist"
                          :key="index"
                          @mouseenter="onopbtnsshowenter(index)"
                          @mouseleave="onopbtnsleave"
                        >
                          <div
                            class="consignee-item"
                            @click="onaddresschecked(item)"
                            :class="
                              curraddress && curraddress.id == item.id
                                ? 'item-selected'
                                : ''
                            "
                          >
                            <span limit="8">{{ item.contactuser }}</span
                            ><b></b>
                          </div>
                          <div class="addr-detail">
                            <span class="addr-name" limit="6">{{
                              item.contactuser
                            }}</span>
                            <span class="addr-info" limit="45"
                              >{{ item.provinceName }}{{ ' ' + item.cityName
                              }}{{ ' ' + item.countyName }}
                              {{ item.contactphoneaddress }}</span
                            >
                            <span class="addr-tel">{{
                              item.contactphone
                            }}</span>
                            <span
                              class="addr-default"
                              v-if="item.isDefault == 1"
                              >默认地址</span
                            >
                          </div>
                          <div
                            class="op-btns"
                            :class="
                              isopbtnsshowindex == index ? 'op-btns-show' : ''
                            "
                          >
                            <a
                              href="javascript:;"
                              class="ftx-05"
                              v-if="item.isDefault != 1"
                              @click="omusershippingaddresssetdefault(item.id)"
                              >设为默认地址</a
                            >
                            <a
                              href="javascript:;"
                              class="ftx-05 edit-consignee"
                              @click="oninitshippingaddress(item)"
                              >编辑</a
                            >
                            <a
                              href="javascript:;"
                              class="ftx-05 del-consignee "
                              @click="onusershippingaddressdel(item.id)"
                              >删除</a
                            >
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="hr"></div>
          <div>
            <div>
              <div class="step-tit">
                <h3>支付方式</h3>
              </div>
              <div class="step-cont">
                <div class="payment-list">
                  <div class="list-cont">
                    <ul>
                      <li style="cursor: pointer;" @click="onpaymentchecked(4)">
                        <div
                          class="payment-item  online-payment"
                          :class="currpaymenttype == 4 ? 'item-selected' : ''"
                        >
                          <b></b> 微信支付
                          <span class="qmark-icon qmark-tip"></span>
                        </div>
                      </li>
                      <li style="cursor: pointer;" @click="onpaymentchecked(3)">
                        <div
                          class="payment-item  online-payment"
                          :class="currpaymenttype == 3 ? 'item-selected' : ''"
                        >
                          <b></b> 支付宝
                          <span class="qmark-icon qmark-tip"></span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="clearfix"></div>
              </div>

              <div class="hr"></div>
              <div class="step-tit">
                <h3>商品清单</h3>
              </div>
              <div class="step-cont" v-if="goodsInfo != null">
                <div class="shopping-lists">
                  <div class="hide"></div>
                  <div class="shopping-list ABTest wxw3">
                    <div class="goods-list">
                      <div class="goods-items">
                        <div></div>
                        <div class="goods-item goods-item-extra" sx-type="">
                          <div class="p-img">
                            <a
                              target="_blank"
                              :href="'/product/detail?uniqueNo=' + uniqueNo"
                            >
                              <img :src="goodsInfoimagePath" alt=""
                            /></a>
                          </div>
                          <div class="goods-msg">
                            <div class="goods-msg-gel">
                              <div class="p-name">
                                <a
                                  target="_blank"
                                  :href="'/product/detail?uniqueNo=' + uniqueNo"
                                >
                                  {{ goodsInfo.title }}
                                </a>
                              </div>
                              <div class="p-price ">
                                <strong class="jd-price">
                                  ￥ {{ price }}
                                  <em class=""></em>
                                </strong>
                                <span
                                  class="p-num"
                                  v-if="goodsInfo.isNeedStock == 1"
                                >
                                  x{{ buynum }}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="p-extra-continer">
                            <div class="p-extra-line">
                              <div
                                class="fl"
                                v-for="(item, key, index) in customstyle"
                                :key="index"
                              >
                                <strong>
                                  <template
                                    v-if="key.indexOf('styletitle') > -1"
                                  >
                                    {{ item + ':' }}
                                  </template>
                                </strong>
                                <span>
                                  <template v-if="key.indexOf('atttitle') > -1">
                                    {{ item }}
                                  </template>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="p-icon-continer">
                            <i class="p-icon p-icon-w sevenicon"></i>
                          </div>
                          <div class="clearfix"></div>
                        </div>
                      </div>
                      <div
                        class="hide service-items ml20 mr20"
                        style="display: block;"
                      >
                        <div
                          class="hide service-item"
                          id="vender_freight_insurance_0"
                          style="display: none;"
                        >
                          <div class="hr"></div>
                          <span class="service-desc">退换无忧</span>
                          <strong class="service-price">￥0.00</strong>
                        </div>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div
                class="step-tit"
                v-if="goodsInfo != null && goodsInfo.isServiceGoods == 1"
              >
                <h3>评估信息</h3>
              </div>
              <div
                class="step-cont"
                v-if="goodsInfo != null && goodsInfo.isServiceGoods == 1"
              >
                <div class="payment-list">
                  <div class="list-cont">
                    <el-form
                      :inline="true"
                      :model="assessAcceptInfo"
                      class="demo-form-inline"
                    >
                      <el-form-item label="客户名称" style="width:400px;">
                        <el-input
                          v-model="customerName"
                          placeholder="客户名称"
                        ></el-input>
                      </el-form-item>
                      <el-form-item label="联系电话" style="width:400px;">
                        <el-input
                          v-model="contactPhone"
                          placeholder="联系电话"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        label="评估类型"
                        style="width:400px;"
                        v-if="goodsInfo != null"
                      >
                        <el-select
                          style="width:100px"
                          v-model="assessType"
                          @change="onchangeassessType"
                          placeholder="评估类型"
                        >
                          <el-option
                            v-for="item in goodsInfo.assessTypeOption"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          >
                          </el-option>
                        </el-select>
                        <el-select
                          style="width:160px"
                          v-model="assessAcceptInfo.reportType"
                          placeholder="请选择"
                          @change="onchangereportType"
                          class="controly"
                        >
                          <el-option
                            v-for="item in goodsInfo.reportTypeOption"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item
                        label="估价目的"
                        label-width="80"
                        style="width:300px;"
                        v-if="goodsInfo != null"
                      >
                        <el-select
                          style="width:200px;"
                          v-model="assessAcceptInfo.assessForId"
                          placeholder="请选择"
                          @change="onchangeassessFor"
                          class="controly"
                        >
                          <el-option
                            v-for="item in goodsInfo.assessForOption"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item
                        class="formL"
                        label="标的地址"
                        prop="ap"
                        style="width:400px;"
                      >
                        <el-cascader
                          ref="addressCascader"
                          style="width:180px;float:left;"
                          v-model="assessAcceptInfoCity"
                          class="city"
                          :options="city"
                          @change="onaddresscascader"
                          clearable
                        ></el-cascader>
                        <div
                          class="tosixth"
                          @click="onshowmappopup()"
                          style="float:left;cursor: pointer;"
                        >
                          <img
                            src="../../assets/img/location.png"
                            width="25"
                            height="25"
                          />
                          <!-- <span>{{ address }}</span> -->
                          <!-- <span class="lookmap" @click="dialogmapVisible = true">查看地图</span> -->
                        </div>
                      </el-form-item>
                      <el-form-item
                        class="formL"
                        label="详细地址"
                        prop="Address"
                      >
                        <el-input v-model="assessAcceptInfo.address"></el-input>
                      </el-form-item>
                    </el-form>
                  </div>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
          <div class="hr"></div>
          <div class="clearfix"></div>
        </div>
        <div class="trade-foot">
          <div class="trade-foot-detail-com">
            <div class="fc-price-info">
              <span class="price-tit">应付总额：</span>
              <span class="price-num">￥{{ totalprice }}</span>
            </div>
            <div class="fc-consignee-info" v-if="curraddress != null">
              <span class="mr20"
                >寄送至：{{ curraddress.provinceName
                }}{{ ' ' + curraddress.cityName
                }}{{ ' ' + curraddress.countyName }}
                {{ curraddress.address }}</span
              ><span
                >收货人： {{ curraddress.contactuser
                }}{{ ' ' + curraddress.contactphone }}
              </span>
            </div>
          </div>
          <div class="group">
            <div class="ui-ceilinglamp checkout-buttons">
              <div class="sticky-wrap">
                <div class="inner">
                  <el-popconfirm
                    title="确认提交订单？"
                    @confirm="onsubmitorder"
                  >
                    <el-button
                      slot="reference"
                      type="primary"
                      class="submitorder"
                      >提交订单</el-button
                    >
                  </el-popconfirm>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
    <Footer v-if="fromoa != '1'" />
    <!-- 登录对话框 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="500px"
      class="shoppingaddress"
      title="新增收货地址"
      @close="onpopupclose"
      :close-on-click-modal="false"
    >
      <div class="form-new" name="consignee-form">
        <el-form
          :model="shippingaddressform"
          :rules="rules"
          label-width="100px"
          class="elshippingform"
          ref="elshippingform"
        >
          <el-form-item class="formL" label="所在地区" prop="ap">
            <el-cascader
              ref="cityCascader"
              style="width:180px"
              class="city"
              :options="city"
              v-model="shippingaddressform.ap"
              @change="oncitycascader"
              clearable
            ></el-cascader>
          </el-form-item>
          <el-form-item class="formL" label="详细地址" prop="Address">
            <el-input v-model="shippingaddressform.Address"></el-input>
          </el-form-item>
          <el-form-item class="formL" label="收货人" prop="contactuser">
            <el-input v-model="shippingaddressform.contactuser"></el-input>
          </el-form-item>

          <el-form-item class="formL" label="手机号码" prop="contactphone">
            <el-input v-model="shippingaddressform.contactphone"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              class="saveshipping"
              @click="onsubmitForm('elshippingform')"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!-- 支付对话框 -->
    <el-dialog
      :visible.sync="dialogpayVisible"
      width="500px"
      class="paypopup"
      title=""
      @close="onpopupclose"
      :close-on-click-modal="false"
    >
      <div class="center">
        <div class="center-item">
          <i class="el-icon-warning"></i>
        </div>
        <div class="center-item">
          <h3>请您在新打开的页面上完成付款。</h3>
          <p>付款完成前请不要关闭此窗口。</p>
          <p>完成付款后请根据您的情况点击下面的按钮：</p>
        </div>
      </div>
      <div class="clearfix"></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="oncheckorderpopup">支付失败</el-button>
        <el-button type="primary" class="success" @click="oncheckorderpopup"
          >支付成功</el-button
        >
      </span>
    </el-dialog>
    <!-- 支付对话框 -->
    <el-dialog
      :visible.sync="dialogwepayVisible"
      class="wepaypopup"
      title=""
      @close="onpopupclose"
      :close-on-click-modal="false"
    >
      <div
        class="modal-box"
        v-loading="paypopuploading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <div class="modal-left">
          <p>
            <span>请使用 </span><span class="orange">微信 </span
            ><i class="icon icon-qrcode"></i><span class="orange"> 扫一扫</span
            ><br />扫描二维码支付
          </p>
          <div
            class="modal-qr"
            v-show="!paypopuploading"
            style="width:250px;height:274px"
          >
            <img
              class="modal-qrcode"
              :src="wepayqrcodeurl"
              alt="您的浏览器版本太低, 请升级您的浏览器"
            />
            <div class="modal-info" style="margin-top:24px">
              <img
                class="icon-clock"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAYCAMAAAAmopZHAAAAxlBMVEUAAAD/iAD/iQD/iwD/iAD/iAD/iQD/igD/hwD/iAD/hwD/iQD/iAD/igD/jAD/iQD/iwD/iAD/iQD/iAD/iwD/hwD/jgD/iAD/iAD/jgD/hwD/gwD/iAD/iwD/iwD/iAD/igD/iQD/igD/igD/igD/iwD/kQD/hwD/nQD/kAD/iQD/kgD/hwD/iQD/lwD/hwD/gwD/hAD/iQD/jQD/ogD/mwD/rAD/dQD/rQD/iAD/nAD/igD/hgD/iAD/gwD/fgD/gQD/jQCSx2meAAAAPHRSTlMA8d9VGdiZN9TDhXhxb2xaIRwF6LyrqXxgQzAqJBML+evlz8ikoH52aWdkXEo/MvbXzLCqg3h0ZmRNKwwyACdhAAABIklEQVQoz12R53aCQBSEB2liQ1BjN1FjiUbT+1zQ93+p7D1A5OT7sTN3Ge42KOseLlzVClcjNyhoJVLLA2S3bdSZRGaMupRbo6MF6QF2zxIRq2cDnkgQYZmwCb9qJjtuR0jXx0a4wLEa4o5cDhxtFQaUJgYdG4ZtyhZyNNPATN33+XSv2v6aqAyFRyiP6TuUfr7B7amickiMKA3pZ8al/r/iLiubUs9MyBczziX6Nz+lBfzINYo+g9xZMoUjN3lV53OcuYq0MfvLO1d8sIt89jHHFnZ9E6C28EqHna3YB3bUC/WTOS6MYtOBB7VP6SvKvCVVKJNzEuKCzXScuf0pWaOgzvSz8HthpTGOEY9bLvlRWi2gvqIIGfgoM/T0KV1vmNe/YLwjroRqPFIAAAAASUVORK5CYII="
              /><span>二维码有效时长为2小时, 请尽快支付</span>
            </div>
          </div>
          <div
            class="modal-qr"
            style="width:250px;height:274px"
            v-show="paypopuploading"
          >
            <img
              class="modal-qrcode"
              style="width:20px;height:20px;margin-top:100px;"
              src="https://mimg.127.net/p/freemail/index/lib/img/loading_s.gif"
              alt="您的浏览器版本太低, 请升级您的浏览器"
            />
            <div class="modal-info" style="margin-top:114px;">
              <img
                class="icon-clock"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAYCAMAAAAmopZHAAAAxlBMVEUAAAD/iAD/iQD/iwD/iAD/iAD/iQD/igD/hwD/iAD/hwD/iQD/iAD/igD/jAD/iQD/iwD/iAD/iQD/iAD/iwD/hwD/jgD/iAD/iAD/jgD/hwD/gwD/iAD/iwD/iwD/iAD/igD/iQD/igD/igD/igD/iwD/kQD/hwD/nQD/kAD/iQD/kgD/hwD/iQD/lwD/hwD/gwD/hAD/iQD/jQD/ogD/mwD/rAD/dQD/rQD/iAD/nAD/igD/hgD/iAD/gwD/fgD/gQD/jQCSx2meAAAAPHRSTlMA8d9VGdiZN9TDhXhxb2xaIRwF6LyrqXxgQzAqJBML+evlz8ikoH52aWdkXEo/MvbXzLCqg3h0ZmRNKwwyACdhAAABIklEQVQoz12R53aCQBSEB2liQ1BjN1FjiUbT+1zQ93+p7D1A5OT7sTN3Ge42KOseLlzVClcjNyhoJVLLA2S3bdSZRGaMupRbo6MF6QF2zxIRq2cDnkgQYZmwCb9qJjtuR0jXx0a4wLEa4o5cDhxtFQaUJgYdG4ZtyhZyNNPATN33+XSv2v6aqAyFRyiP6TuUfr7B7amickiMKA3pZ8al/r/iLiubUs9MyBczziX6Nz+lBfzINYo+g9xZMoUjN3lV53OcuYq0MfvLO1d8sIt89jHHFnZ9E6C28EqHna3YB3bUC/WTOS6MYtOBB7VP6SvKvCVVKJNzEuKCzXScuf0pWaOgzvSz8HthpTGOEY9bLvlRWi2gvqIIGfgoM/T0KV1vmNe/YLwjroRqPFIAAAAASUVORK5CYII="
              /><span>二维码有效时长为2小时, 请尽快支付</span>
            </div>
          </div>
        </div>
        <div class="modal-right">
          <i class="icon icon-close"></i
          ><img src="../../assets/img/weixin-qrcode.jpg" alt="微信扫码" />
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogmapVisible"
      class="map"
      top="15vh"
      width="40%"
      :before-close="handleClose"
    >
      <el-row>
        <div class="amap-page-container" style="margin-top:-15px;">
          <div class="address">{{ address }}</div>
          <el-amap
            vid="amaprecruitposition"
            :center="center"
            :zoom="zoom"
            class="amap-demo"
            :events="events"
          >
            <el-amap-marker
              v-for="(marker, index) in markers"
              :position="marker"
              :key="index"
            ></el-amap-marker>
          </el-amap>
          <div style="text-align:center;margin-top:20px;">
            <el-button type="primary" size="mini" @click="locatefal"
              ><span style="margin:0 30px">确定</span></el-button
            >
          </div>
        </div>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import Header from '@/components/header.vue';
import Footer from '@/components/footer.vue';
import { productgetproductbyuniqueno } from '@/api/product';
import {
  ordersubmitorder,
  payalipagepay,
  ordergetorderinfo,
  paywechatnativepay,
  paystate
} from '@/api/order';
import {
  usershippingaddressgetlist,
  usershippingaddressinsertorupdate,
  usershippingaddressdel,
  usershippingaddresssetdefault
} from '@/api/user';
import { cityjson } from '@/utils/cityjson.js';
export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  data() {
    let self = this;
    return {
      assessAcceptInfoCity: [],
      assessType: '',
      customerName: '',
      contactPhone: '',
      assessTypeOption: [],
      dialogmapVisible: false,
      assessAcceptInfo: {
        assessType: 0,
        reportType: '',
        rptCatId: 0,
        assessFor: '',
        assessForId: 0,
        provinceName: '',
        provinceId: 0,
        cityName: '',
        cityId: 0,
        countyName: '',
        countyId: 0,
        address: '',
        longitude: '',
        latitude: ''
        //acceptImgBase64: ['']
      },
      form: {
        workAddress: {
          city: '',
          cityCode: '',
          county: '',
          countyCode: '',
          latitude: 0,
          longitude: 0,
          province: '',
          provinceCode: '',
          srid: 'GCJ-02'
        },
        position: {
          businessId: '',
          description: '',
          name: '',
          parentId: ''
        }
      },
      fromoa: '',
      paypopuploading: true,
      wepayqrcodeurl: '',
      tradeId: '',
      orderId: 0,
      dialogpayVisible: false,
      dialogwepayVisible: false,
      isopbtnsshowindex: -1,
      dataform: null,
      totalprice: 0,
      customstyle: '',
      price: 0,
      buynum: 0,
      customAttrList: [],
      goodsInfoimagePath: '',
      goodsInfo: null,
      customid: 0,
      currpaymenttype: 4,
      curraddress: null,
      province: [],
      city: cityjson,
      provinceid: 0,
      provincename: '',
      cityid: 0,
      cityname: '',
      countyid: 0,
      countyname: '',
      citylist: [],
      isopbtnsshow: false,
      dialogVisible: false,
      dialogmapVisible: false,
      shippingaddresslist: [],
      checkordertimer: null,
      shippingaddressform: {
        contactuser: '',
        contactphone: '',
        ProvinceId: '',
        ProvinceName: '',
        CityId: '',
        CityName: '',
        CountyId: '',
        CountyName: '',
        Address: '',
        Alias: '',
        IsDefault: 0,
        ap: [],
        id: 0
      },
      rules: {
        contactuser: [{ required: true, message: '请填写收货人' }],
        contactphone: [{ required: true, message: '请填写联系电话' }],
        ap: [{ required: true, message: '请选择区域' }],
        Address: [{ required: true, message: '请填写详细收货地址' }]
      },
      uniqueNo: '',
      productId: 0,
      markers: [],
      center: [117.127665, 36.679559],
      zoom: 15,
      // address: '',
      lng: 117.127665,
      lat: 36.679559,
      address: '',
      addForm: {
        longitude: '',
        latitude: ''
      },
      events: {
        click(e) {
          self.markers = [];
          let { lng, lat } = e.lnglat;
          self.addForm.longitude = lng;
          self.addForm.latitude = lat;
          self.center = [lng, lat];
          self.markers.push([lng, lat]);
          // 这里通过高德 SDK 完成。
          var geocoder = new AMap.Geocoder({
            radius: 1000,
            extensions: 'all'
          });
          geocoder.getAddress([lng, lat], function(status, result) {
            if (status === 'complete' && result.info === 'OK') {
              if (result && result.regeocode) {
                //self.address = result.regeocode.formattedAddress;
                self.assessAcceptInfo.address =
                  result.regeocode.formattedAddress;
                self.form.workAddress.city =
                  result.regeocode.addressComponent.city;
                self.form.workAddress.province =
                  result.regeocode.addressComponent.province;

                self.form.workAddress.county =
                  result.regeocode.addressComponent.district;
                self.form.workAddress.countyCode =
                  result.regeocode.addressComponent.adcode;
                self.form.workAddress.cityCode =
                  result.regeocode.addressComponent.adcode.substring(0, 4) +
                  '00';
                self.form.workAddress.provinceCode =
                  result.regeocode.addressComponent.adcode.substring(0, 2) +
                  '0000';
                self.form.workAddress.srid = 'GCJ-02';

                self.assessAcceptInfo.provinceName =
                  self.form.workAddress.province;
                self.assessAcceptInfo.cityName = self.form.workAddress.city;
                self.assessAcceptInfo.countyName = self.form.workAddress.county;
                self.assessAcceptInfo.provinceId =
                  self.form.workAddress.provinceCode;
                self.assessAcceptInfo.cityId = self.form.workAddress.cityCode;
                self.assessAcceptInfo.countyId =
                  self.form.workAddress.countyCode;
                self.assessAcceptInfo.latitude =
                  result.regeocode.crosses[0].location.lat;
                self.assessAcceptInfo.longitude =
                  result.regeocode.crosses[0].location.lng;

                self.assessAcceptInfoCity = [];
                self.assessAcceptInfoCity.push(
                  self.assessAcceptInfo.provinceId
                );
                self.assessAcceptInfoCity.push(self.assessAcceptInfo.cityId);
                self.assessAcceptInfoCity.push(self.assessAcceptInfo.countyId);

                console.log(self.assessAcceptInfo);
                //self.formjob.companyAddress = result.regeocode.formattedAddress;

                // self.formjob.gisCoord.province =
                //   result.regeocode.addressComponent.province;
                // self.formjob.gisCoord.latitude =
                //   result.regeocode.crosses[0].location.lat;
                // self.formjob.gisCoord.longitude =
                //   result.regeocode.crosses[0].location.lng;
                // self.formjob.gisCoord.county =
                //   result.regeocode.addressComponent.district;
                // self.formjob.gisCoord.countyCode =
                //   result.regeocode.addressComponent.adcode;
                // self.formjob.gisCoord.cityCode =
                //   result.regeocode.addressComponent.adcode.substring(0, 4) +
                //   '00';
                // self.formjob.gisCoord.provinceCode =
                //   result.regeocode.addressComponent.adcode.substring(0, 2) +
                //   '0000';
                // self.formjob.gisCoord.srid = 'GCJ-02';
                self.$nextTick();
              }
            }
          });
        }
      }
    };
  },
  methods: {
    onshowmappopup() {
      const self = this;
      self.location();
      this.dialogmapVisible = true;
    },
    location() {
      var self = this;
      var geolocation = new AMap.Geolocation({
        // 是否使用高精度定位，默认：true
        enableHighAccuracy: true,
        maximumAge: 0, //定位结果缓存0毫秒，默认：0
        showButton: true, //显示定位按钮，默认：true
        buttonPosition: 'LB', //定位按钮停靠位置，默认：'LB'，左下角
        buttonOffset: new AMap.Pixel(10, 20),
        showMarker: true,
        panToLocation: true,
        zoomToAccuracy: true,
        // 设置定位超时时间，默认：无穷大
        timeout: 5000
      });
      geolocation.getCurrentPosition();
      AMap.event.addListener(geolocation, 'complete', onComplete);
      AMap.event.addListener(geolocation, 'error', onError);
      function onComplete(data) {
        let cityName = data.addressComponent.city;
        let geocoder = new AMap.Geocoder({});
        //地理编码,返回地理编码结果，
        geocoder.getLocation(cityName, function(status, result) {
          if (status === 'complete' && result.info === 'OK') {
            let cityAdcode = result.geocodes[0].adcode;
            self.cityid = cityAdcode;
            localStorage.setItem('cityid', self.cityid);
            self.currcityitem = { id: self.cityid, areaname: cityName };
            localStorage.setItem('locationcity', cityName);
            localStorage.setItem(
              'currcityitem',
              JSON.stringify(self.currcityitem)
            );
            self.center = [];
            console.log(result);
            self.center.push(result.geocodes[0].location.lng);
            self.center.push(result.geocodes[0].location.lat);

            // self.assessAcceptInfo.latitude =
            //     result.regeocode.crosses[0].location.lat;
            //   self.assessAcceptInfo.longitude =
            //     result.regeocode.crosses[0].location.lng;

            // self.refresh();
          }
        });
        localStorage.setItem('cityname', self.cityname);

        setTimeout(function() {
          self.cityname = data.addressComponent.city;
          localStorage.setItem('cityname', self.cityname);

          self.locationcity = data.addressComponent.city;
        }, 100);
        self.cityname = data.addressComponent.city;
      }
      function onError(data) {
        // 失败 启用 ip定位
        AMap.plugin('AMap.CitySearch', function() {
          var citySearch = new AMap.CitySearch();
          citySearch.getLocalCity(function(status, result) {
            if (status === 'complete' && result.info === 'OK') {
              // 查询成功，result即为当前所在城市信息
              // console.log(result)
              self.center = [result.bounds.Gb.lng, result.bounds.Gb.lat];
              geocoder.getAddress(
                [result.bounds.Gb.lng, result.bounds.Gb.lat],
                function(status, result) {
                  if (status === 'complete' && result.info === 'OK') {
                    if (result && result.regeocode) {
                      self.cityname = data.addressComponent.city;
                      self.cityid = data.addressComponent.adcode;

                      let geocoder1 = new AMap.Geocoder({});
                      //地理编码,返回地理编码结果，
                      geocoder1.getLocation(cityName, function(status, result) {
                        if (status === 'complete' && result.info === 'OK') {
                          let cityAdcode = result.geocodes[0].adcode;
                          self.cityid = cityAdcode;
                          localStorage.setItem('cityid', self.cityid);
                        }
                      });

                      localStorage.setItem('cityname', self.cityname);
                      //localStorage.setItem('cityid', self.cityid);
                      self.$nextTick();
                    } else {
                      self.cityname = '郑州市';
                      self.cityid = '410100';
                      localStorage.setItem('cityname', self.cityname);
                      localStorage.setItem('cityid', self.cityid);
                    }
                  } else {
                    self.cityname = '郑州市';
                    self.cityid = '410100';
                    localStorage.setItem('cityname', self.cityname);
                    localStorage.setItem('cityid', self.cityid);
                  }
                }
              );
            }
            // self.refresh();
          });
        });
      }
    },
    onchangeassessFor(value) {
      let obj = this.goodsInfo.assessForOption.find(item => {
        return item.id == value;
      });
      this.assessAcceptInfo.assessFor = obj.name;
      this.assessAcceptInfo.assessForId = value;
    },
    onchangereportType(value) {
      let obj = this.goodsInfo.reportTypeOption.find(item => {
        return item.id == value;
      });
      this.assessAcceptInfo.reportType = obj.name;
      this.assessAcceptInfo.rptCatId = value;
    },
    onchangeassessType(value) {
      let obj = this.assessTypeOption.find(item => {
        return item.id == value;
      });
      this.assessAcceptInfo.assessType = obj.name;
    },
    onopbtnsshowenter(index) {
      this.isopbtnsshowindex = index;
    },
    onopbtnsleave() {
      this.isopbtnsshowindex = -1;
    },
    onsubmitorder() {
      console.error('onsubmitorder');
      if (this.curraddress == null) {
        this.$message.error('请添加收货地址');
        return;
      }
      this.dataform = {};
      this.dataform['payType'] = this.currpaymenttype;
      this.dataform['addressId'] = this.curraddress.id;
      this.dataform['isSaleOrder'] = this.fromoa == '1' ? 1 : 0;
      this.dataform['prodList'] = [];
      this.dataform['deliveryType'] = 2;
      this.dataform['provinceName'] = '';
      this.dataform['provinceId'] = 0;
      this.dataform['cityName'] = '';
      this.dataform['cityId'] = 0;
      this.dataform['countyName'] = '';
      this.dataform['countyId'] = 0;
      this.dataform['address'] = '';
      this.dataform['customerName'] = this.customerName;
      this.dataform['contactPhone'] = this.contactPhone;
      this.dataform['acceptUserId'] = 0;
      this.dataform['acceptCompanyId'] = 0;

      let goods = {
        productId: this.productId,
        count: this.buynum,
        unitPrice: this.price,
        skuAttrOptionId: []
      };

      if (this.goodsInfo.isServiceGoods == 1) {
        if (this.customerName == '') {
          this.$message.error('请填写客户名称');
          return;
        }
        if (this.contactPhone == '') {
          this.$message.error('请填写客户联系电话');
          return;
        }
        if (this.contactPhone == '') {
          this.$message.error('请填写客户联系电话');
          return;
        }
        if (this.assessAcceptInfo.rptCatId == 0) {
          this.$message.error('请选择评估类型');
          return;
        }
        if (this.assessAcceptInfo.assessForId == 0) {
          this.$message.error('请选择估价目的');
          return;
        }
        if (this.assessAcceptInfo.countyId == 0) {
          this.$message.error('请选择标的城市');
          return;
        }
        if (this.assessAcceptInfo.address == 0) {
          this.$message.error('请输入标的地址');
          return;
        }
        goods.assessAcceptInfo = this.assessAcceptInfo;
      }
      console.log(this.assessAcceptInfo);

      if (this.price == 0) {
        this.$message.error('价格有误');
        return;
      }
      this.dataform['prodList'].push(goods);
      let openWindow = null;
      if (this.currpaymenttype == 3) {
        openWindow = window.open('about:blank');
      }
      console.log(this.dataform);
      ordersubmitorder(this.dataform).then(res => {
        if (res.data.code === 1) {
          let form = {};
          form['totalAmount'] = res.data.result.totalAmount;
          form['id'] = res.data.result.orderId;
          form['returnUrl'] = process.env.VUE_APP_HOST + '/shopping/payresult';
          if (this.checkordertimer != null) {
            window.clearInterval(this.checkordertimer);
          }
          this.checkordertimer = setInterval(this.oncheckorder, 2500);
          this.orderId = res.data.result.orderId;
          if (this.currpaymenttype == 4) {
            this.dialogwepayVisible = true;
            paywechatnativepay(form).then(res => {
              this.paypopuploading = false;
              if (res.data.code == 1) {
                this.wepayqrcodeurl =
                  'data:image/png;base64,' + res.data.result.qrcodeBase64;
                this.tradeId = res.data.result.tradeId;
              } else {
                this.$message.error(res.data.message);
              }
            });
          } else {
            this.dialogpayVisible = true;
            let payurl =
              process.env.VUE_APP_URL3 +
              '/pay/alipagepay?id=' +
              form.id +
              '&totalAmount=' +
              form.totalAmount +
              '&returnUrl=' +
              encodeURIComponent(form.returnUrl);
            openWindow.location = payurl;

            //var a = document.createElement('a');
            //a.setAttribute('href', payurl);
            //a.setAttribute('target', '_blank');
            //a.setAttribute('id', res.data.result.orderId);
            //if (!document.getElementById(res.data.result.orderId))
            //  document.body.appendChild(a);
            //a.click();
          }
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    oncheckorderpopup() {
      if (this.tradeId != '') {
        let obj = { tradeId: this.tradeId };
        paystate(obj).then(res => {
          if (res.data.code === 1) {
            this.checkordertimer = null;
            this.dialogpayVisible = false;
            if (res.data.code === 1) {
              setTimeout(function() {
                window.location.href = '/index';
              }, 500);
            } else {
              this.$message.error('支付未成功，请重新支付');
            }
          }
        });
      } else {
        let obj = { orderid: this.orderId };
        ordergetorderinfo(obj).then(res => {
          if (res.data.code === 1) {
            this.checkordertimer = null;
            this.dialogpayVisible = false;
            if (res.data.result.orderState == 120) {
              setTimeout(function() {
                window.location.href = '/index';
              }, 500);
            } else {
              this.$message.error('支付未成功，请重新支付');
            }
          }
        });
      }
    },
    oncheckorder() {
      if (this.tradeId != '') {
        let obj = { tradeId: this.tradeId };
        paystate(obj).then(res => {
          if (res.data.code === 1) {
            this.checkordertimer = null;
            //if (res.data.result.orderState == 120) {
            this.dialogpayVisible = false;
            setTimeout(function() {
              window.location.href = '/index';
            }, 1000);
            //}
          }
        });
      } else {
        let obj = { orderid: this.orderId };
        ordergetorderinfo(obj).then(res => {
          if (res.data.code === 1) {
            this.checkordertimer = null;
            if (res.data.result.orderState == 120) {
              this.dialogpayVisible = false;
              setTimeout(function() {
                window.location.href = '/index';
              }, 1000);
            }
          }
        });
      }
    },
    onpayalipagepay(form) {
      payalipagepay(form).then(res => {
        if (res.data.code === 1) {
          console.log(res.data);
        }
      });
    },
    onproductgetproductbyuniqueno() {
      var that = this;
      productgetproductbyuniqueno({ uniqueno: that.uniqueNo }).then(res => {
        if (res.data.code === 1) {
          this.goodsInfo = res.data.result;
          this.imgList = res.data.result.picList;
          this.goodsInfoimagePath = this.imgList[0];
          this.price =
            res.data.result.prepayAmount > 0
              ? res.data.result.prepayAmount
              : res.data.result.price;
          this.totalprice = parseFloat(this.price) * parseFloat(this.buynum);
          this.productId = res.data.result.productId;

          let obj = {
            id: this.goodsInfo.assessType,
            name: this.goodsInfo.assessTypeName
          };
          this.assessTypeOption.push(obj);
          this.assessAcceptInfo.assessType = this.goodsInfo.assessType;
          this.assessType = this.goodsInfo.assessTypeName;
          if (
            this.goodsInfo.assessForOption != null &&
            this.goodsInfo.assessForOption.length > 0
          ) {
            this.assessAcceptInfo.assessFor = this.goodsInfo.assessForOption[0].name;
            this.assessAcceptInfo.assessForId = this.goodsInfo.assessForOption[0].id;
          }
          if (
            this.goodsInfo.reportTypeOption != null &&
            this.goodsInfo.reportTypeOption.length > 0
          ) {
            this.assessAcceptInfo.reportType = this.goodsInfo.reportTypeOption[0].name;
            this.assessAcceptInfo.rptCatId = this.goodsInfo.reportTypeOption[0].id;
          }
        }
      });
    },
    onpaymentchecked(item) {
      this.currpaymenttype = item;
    },
    onaddresschecked(item) {
      this.curraddress = item;
    },
    oninitshippingaddress(item) {
      this.dialogVisible = true;
      this.shippingaddressform.contactuser = item.contactuser;
      this.shippingaddressform.contactphone = item.contactphone;
      this.shippingaddressform.ProvinceId = item.provinceId;
      this.shippingaddressform.CityId = item.cityId;
      this.shippingaddressform.ProvinceName = item.provinceName;
      this.shippingaddressform.CityName = item.cityName;
      this.shippingaddressform.CountyName = item.countyName;
      this.shippingaddressform.CountyId = item.countyId;
      this.shippingaddressform.Address = item.address;
      this.shippingaddressform.Alias = item.alias;
      this.shippingaddressform.IsDefault = item.isDefault;
      this.shippingaddressform.id = item.id;
      this.shippingaddressform.ap = [
        '' + item.provinceId,
        '' + item.cityId,
        '' + item.countyId
      ];
    },
    onusershippingaddressdel(id) {
      let form = { id: id };
      usershippingaddressdel(form).then(res => {
        if (res.data.code === 1) {
          this.onusershippingaddressgetlist();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    omusershippingaddresssetdefault(id) {
      let form = { id: id };
      usershippingaddresssetdefault(form).then(res => {
        if (res.data.code === 1) {
          this.onusershippingaddressgetlist();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    oncitycascader(item) {
      var labels = this.$refs.cityCascader.getCheckedNodes()[0].pathLabels;
      this.shippingaddressform.ProvinceName = labels[0];
      this.shippingaddressform.CityName = labels[1];
      this.shippingaddressform.CountyName = labels[2];
      this.shippingaddressform.ProvinceId = item[0];
      this.shippingaddressform.CityId = item[1];
      this.shippingaddressform.CountyId = item[2];
    },
    onaddresscascader(item) {
      var labels = this.$refs.addressCascader.getCheckedNodes()[0].pathLabels;
      this.assessAcceptInfo.provinceName = labels[0];
      this.assessAcceptInfo.cityName = labels[1];
      this.assessAcceptInfo.countyName = labels[2];
      this.assessAcceptInfo.provinceId = item[0];
      this.assessAcceptInfo.cityId = item[1];
      this.assessAcceptInfo.countyId = item[2];

      //console.log(this.assessAcceptInfoCity)
    },
    onpopupclose() {},
    onshowaddpop() {
      this.dialogVisible = true;
    },
    onusershippingaddressgetlist() {
      var that = this;
      usershippingaddressgetlist({ count: 0 }).then(res => {
        if (res.data.code === 1) {
          that.shippingaddresslist = res.data.result;
          for (var i = 0; i < that.shippingaddresslist.length; i++) {
            if (that.shippingaddresslist[i].isDefault == 1) {
              that.curraddress = that.shippingaddresslist[i];
              that.customerName = that.curraddress.contactuser;
              that.contactPhone = that.curraddress.contactphone;
            }
          }
        }
      });
    },
    onsubmitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          usershippingaddressinsertorupdate(this.shippingaddressform).then(
            res => {
              if (res.data.code === 1) {
                this.onusershippingaddressgetlist();
                this.dialogVisible = false;
                this.$message({
                  message: '恭喜你，提交成功',
                  type: 'success'
                });
              } else {
                this.$message.error(res.data.message);
              }
            }
          );
        } else {
          console.log('提交失败');
          return false;
        }
      });
    },
    handleClose() {
      this.dialogmapVisible = false;
    },
    locatefal() {
      this.dialogmapVisible = false;
    }
  },
  watch: {},
  mounted() {},
  created() {
    this.onusershippingaddressgetlist();
    this.customid = this.$route.query.customid;
    this.fromoa = this.$route.query.fromoa;
    if (this.$route.query.customstyle) {
      let attrarr = {};
      this.customstyle = JSON.parse(this.$route.query.customstyle);
      let tempcustomstyle = this.customstyle;
      Object.keys(this.customstyle).forEach(function(key) {
        let keyarr = key.split('_');
        if (!attrarr.hasOwnProperty(keyarr[0])) {
          attrarr[keyarr[0]] = {};
        }
        if (keyarr[1] == 'id') {
          attrarr[keyarr[0]].customAttrId = tempcustomstyle[key];
        }
        if (keyarr[1] == 'img' && keyarr[0] != 'Style') {
          let customImgIdList = [];
          customImgIdList.push(tempcustomstyle[key]);
          attrarr[keyarr[0]].customImgIdList = customImgIdList;
        }
        if (keyarr[1] == 'piclist' && keyarr[0] == 'Style') {
          let customImgIdList = [];
          for (var i = 0; i < tempcustomstyle[key].length; i++) {
            customImgIdList.push(tempcustomstyle[key][i].id);
          }
          attrarr[keyarr[0]].customImgIdList = customImgIdList;
        }
      });
      let temcustomAttrList = [];
      Object.keys(attrarr).forEach(function(key) {
        temcustomAttrList.push(attrarr[key]);
      });
      this.customAttrList = temcustomAttrList;
    }
    this.uniqueNo = this.$route.query.uniqueNo;

    //this.price = this.$route.query.price;
    this.buynum = this.$route.query.buynum;
    //this.totalprice = parseFloat(this.price) * parseFloat(this.buynum);
    this.onproductgetproductbyuniqueno();
  }
};
</script>
<style scoped>
body {
  background-color: #fff;
}
img {
  border: 0;
  vertical-align: middle;
}
ul,
span {
  font-size: 14px;
}
.ftx-02,
.ftx02 {
  color: #71b247;
}
.clearfix {
  clear: both;
}

#container {
  padding-bottom: 20px;
  background-color: #fff;
}
.w {
  width: 990px;
  margin: 0 auto;
}
.checkout-tit {
  height: 42px;
  line-height: 42px;
  font-size: 18px;
  font-family: 'Microsoft YaHei';
  overflow: hidden;
}
.checkout-tit .extra-l,
.checkout-tit .tit-txt {
  float: left;
}
.checkout-steps {
  background-color: #fff;
  padding: 0 20px;
  border: 2px solid #e6e6e6;
}
.checkout-steps .step-tit {
  line-height: 40px;
  height: 40px;
  color: #333;
}
.checkout-steps .step-tit h3 {
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  float: left;
}
.checkout-steps .step-tit .extra-r {
  float: right;
}
.ftx-05,
.ftx05 {
  color: #005ea7;
  margin: 0 5px;
}
.checkout-steps .step-cont {
  margin-bottom: 10px;
}
.consignee-content {
  overflow: hidden;
  /* max-height: 168px; */
}
.consignee-scrollbar {
  width: 100%;
}
.consignee-scroll {
  position: relative;
  zoom: 1;
}
.consignee-scroll .consignee-cont {
  margin: 0 10px 0 20px;
  overflow: hidden;
}

.consignee-scroll .consignee-cont li {
  list-style: none;
  height: 30px;
  margin: 6px 0;
  float: left;
  width: 99.8%;
}
.consignee-scroll .consignee-item {
  list-style: none;
  position: relative;
  border: 1px solid #ddd;
  height: 18px;
  line-height: 18px;
  padding: 5px 10px;
  width: 120px;
  text-align: center;
  cursor: pointer;
  background-color: #fff;
}
.consignee-scroll .consignee-cont .addr-default {
  display: inline-block;
  *float: left;
  margin: 5px 10px;
  background-color: #999;
  color: #fff;
  padding: 0 3px;
  line-height: 20px;
}
.consignee-scroll .consignee-cont .consignee-item,
.consignee-scroll .consignee-cont .consignee-item-disable {
  float: left;
}
.consignee-scroll .consignee-item.item-hover,
.consignee-scroll .consignee-item.item-selected,
.consignee-scroll .consignee-item:hover {
  border: 2px solid #e4393c;
  padding: 4px 10px;
}
.consignee-scroll .consignee-cont .addr-detail {
  float: left;
  height: 30px;
  line-height: 30px;
}
.consignee-scroll .consignee-cont .addr-detail span {
  display: inline-block;
  margin-left: 10px;
  *float: left;
}
.consignee-scroll .consignee-cont .addr-ops,
.consignee-scroll .consignee-cont .op-btns {
  visibility: hidden;
  float: right;
  text-align: right;
  height: 30px;
  line-height: 30px;
}
.consignee-scroll .consignee-cont .op-btns.op-btns-show {
  visibility: visible;
}
.payment-list {
  padding: 0 20px 10px;
}
.payment-list .list-cont ul {
  display: inline-block;
  display: block;
}
.payment-list .list-cont li {
  float: left;
  margin-right: 9px;
  _display: inline;
  cursor: default;
}
.payment-list .list-cont .payment-item {
  float: left;
  position: relative;
  border: 1px solid #ddd;
  height: 18px;
  line-height: 18px;
  padding: 5px 24px;
  cursor: pointer;
}
.hr {
  border-bottom: 1px solid #e6e6e6;
  height: 0;
  margin-bottom: 10px;
}
.payment-list {
  padding: 0 20px 10px;
}
.payment-list .list-cont .payment-item.item-selected {
  border: 2px solid #e4393c;
  padding: 4px 23px;
}
.payment-list .list-cont .payment-item.item-selected b {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 12px;
  height: 12px;
  overflow: hidden;
  background: url(//misc.360buyimg.com/user/purchase/2.0.0/css/i/selected-icon.png)
    no-repeat;
}
.consignee-scroll .consignee-item.item-selected b {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 12px;
  height: 12px;
  overflow: hidden;
  background: url(//misc.360buyimg.com/user/purchase/2.0.0/css/i/selected-icon.png)
    no-repeat;
}
.shopping-list.ABTest .goods-list {
  background-color: #f3fbfe;
  padding: 10px 0;
}
.shopping-list .goods-tit {
  height: 24px;
  padding: 0 20px;
}
.shopping-list .goods-list .goods-items {
  padding: 0 20px;
}
.shopping-list .goods-list .goods-item {
  border-bottom: 1px solid #fff;
  padding: 10px 0;
  margin-bottom: 10px;
  color: #666;
}
.shopping-list .goods-list .goods-item-extra {
  border: none;
  margin-bottom: 0;
}
.shopping-list .goods-list .goods-item .p-img {
  float: left;
  width: 80px;
  height: 80px;
  border: 1px solid #ddd;
  margin-right: 10px;
  background-color: #fff;
}
.shopping-list .goods-list .goods-item .p-img img {
  width: 80px;
  height: 80px;
}
.shopping-list .goods-list .goods-item .goods-msg {
  float: left;
  width: 784px;
}
.shopping-list .goods-list .goods-item .goods-msg-gel {
  float: left;
  width: 784px;
}
.shopping-list .goods-list .goods-item .p-name {
  float: left;
  width: 80%;
  overflow: hidden;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 500;
}
.shopping-list .goods-list .goods-item .p-name a {
  color: #666;
}
.shopping-list .goods-list .goods-item .p-price {
  float: right;
  width: 18.8%;
  margin: 0 0 5px;
}
.shopping-list .goods-list .goods-item .p-extra-continer {
  width: 49.8%;
  display: inline-block;
}
.order-summary .statistic {
  width: 300px;
  margin-bottom: 10px;
  margin-right: 20px;
}
.order-summary .statistic .list {
  height: 24px;
  line-height: 24px;
  overflow: hidden;
  margin-bottom: 5px;
  zoom: 1;
}
.order-summary .statistic span {
  width: 180px;
  margin-right: 20px;
  float: left;
  text-align: right;
}
.order-summary .statistic em {
  color: #e4393c;
}
.order-summary .statistic .price {
  color: #333;
  float: left;
  width: 100px;
  text-align: right;
  font-family: verdana;
}
.trade-foot-detail-com {
  padding: 15px 10px 15px 0;
  line-height: 28px;
  text-align: right;
  width: 980px;
  color: #999;
  background-color: #f4f4f4;
}
.trade-foot-detail-com .fc-price-info .price-tit,
.trade-foot-detail-com .presale-price-info .price-tit {
  color: #666;
}
.trade-foot-detail-com .fc-price-info .price-num,
.trade-foot-detail-com .presale-price-info .price-num {
  color: #e4393c;
  font-family: Verdana;
  font-weight: 700;
  font-size: 18px;
  min-width: 122px;
  _width: 122px;
  float: right;
  *float: none;
  text-align: right;
}

.checkout-buttons {
  padding: 10px 0;
  text-align: right;
}
.checkout-buttons .sticky-wrap .inner {
  padding: 0 10px;
}
.form-new {
  position: relative;
  height: 300px;
}
.form-new >>> .el-form-item {
  display: block;
  margin-bottom: 18px;
  line-height: 25px;
}
.form-new >>> .el-input {
  width: 260px;
}
.form-new >>> .el-form-item .el-input__inner {
  border-radius: 0;
  height: 34px;
  line-height: 34px;
}

.form-new >>> .el-form-item .el-form-item__label {
  font-size: 12px;
  width: 76px !important;
}
.form-new >>> .el-form-item .el-form-item__content {
  margin-left: 76px !important;
}
.form-new .item span.label {
  float: left;
  height: 18px;
  line-height: 18px;
  padding: 4px 10px 3px;
  width: 75px;
  text-align: left;
}
.group .checkout-buttons .submitorder {
  background-color: rgb(231, 70, 73);
  border-color: rgb(231, 70, 73);
  margin-top: 10px;
}
.form-new .saveshipping {
  background-color: rgb(231, 70, 73);
  border-color: rgb(231, 70, 73);
  margin-top: 10px;
  color: #fff;
  padding: 8px 15px;
}

>>> .el-dialog {
  border: 6px solid #bbb;
  height: 280px;
}
>>> .shoppingaddress .el-dialog {
  height: auto;
}
>>> .paypopup .el-dialog .el-dialog__header {
  height: 23px;
  position: relative;
  background-color: #f7f7f7;
}
>>> .paypopup .el-dialog .dialog-footer {
  margin: 0 auto;
  display: block;
  width: 50%;
}
>>> .paypopup .el-dialog .dialog-footer .success {
  background-color: #e4393c;
  border-color: #e4393c;
}
>>> .paypopup .el-dialog .center .center-item {
  display: block;
  float: left;
}
>>> .paypopup .el-dialog .center .center-item i {
  font-size: 60px;
  padding: 0 15px 0 48px;
  color: #2cb8aa;
}

.wepaypopup >>> .el-dialog {
  border: 6px solid #bbb;
  width: 728px;
  height: 451px;
}
.wepaypopup >>> .el-dialog .el-dialog__header,
.wepaypopup >>> .el-dialog .el-dialog__body {
  padding: 0;
}
.wepaypopup >>> .el-dialog .modal-left {
  float: left;
  width: 250px;
  padding: 20px 50px 25px 45px;
}

.wepaypopup >>> .el-dialog p {
  font-size: 18px;
  text-align: center;
  line-height: 32px;
  margin-bottom: 16px;
}

.wepaypopup >>> .el-dialog .modal-left span {
  vertical-align: middle;
}
.wepaypopup >>> .el-dialog .orange {
  color: #f80;
}
.icon-qrcode {
  width: 16px;
  height: 16px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  background-position: 0 -88px;
  background: url(../../assets/img/qsao.png) no-repeat;
  background-size: cover;
}
.wepaypopup >>> .el-dialog .modal-qr {
  padding-top: 20px;
  border: 1px solid #ddd;
}
.wepaypopup >>> .el-dialog .modal-qrcode {
  width: 210px;
  height: 210px;
  display: block;
  margin: 0 auto 20px;
}
.wepaypopup >>> .el-dialog .modal-info {
  height: 14px;
  color: #f80;
  font-size: 12px;
  line-height: 1;
  padding: 13px 0;
  text-align: center;
  background-color: #f7f7f7;
}
.icon-clock {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.wepaypopup >>> .el-dialog .modal-right {
  float: left;
}
.wepaypopup >>> .el-dialog .modal-right img {
  width: 371px;
  height: 438px;
}
.amap-demo {
  height: 450px;
}
>>> .map .el-dialog {
  height: auto;
}
</style>
